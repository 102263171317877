.resourceContainer {
  max-width: 1500px;
  margin: 0 auto;
  font-family: Roboto;

  .drawResourceIntroductionTitle {
    max-width: 850px;
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    line-height: 64px;
    margin: 0 auto;
  }

  .resourceIconBox {
    display: flex;
    justify-content: left;
    row-gap: 10px;
    column-gap: 50px;
    max-width: max-content;
    flex-wrap: wrap;
    margin: 27px auto 5px auto;

    .resourceIconItem {
      display: flex;
      align-items: center;
      align-self: stretch;

      font-size: 17px;
      text-align: left;
      color: #000000;


      .resourceIconListItem {
        display: block;
        width: 28px;
        height: 28px;
        background: linear-gradient(290deg, #3f92ff 4%, #833ee4 45%, #ff7750 100%);
        border-radius: 50%;
        margin-right: 10px;

        &:before {
          content: '';
          display: block;
          width: 10px;
          height: 5px;
          border-left: 3px solid #FFFFFF;
          border-bottom: 3px solid #FFFFFF;
          border-radius: 1px;
          transform: rotate(-45deg) translate(calc(50% - 7px), calc(50% + 7px));
          transform-origin: center center;
        }
      }
    }
  }

  .resourceIntroductionBox {
    display: flex;
    justify-content: space-between;
    list-style: none;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;

    .resourceIntroductionItemItem {
      width: 480px;
      max-width: 100%;
      height: 340px;
      position: relative;
      border-radius: 16px;
      margin-top: 30px;
      overflow: hidden;
      display: flex;

      & > div {
        font-size: 36px;
        font-weight: bold;
        text-align: left;
        color: #080a10;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        margin: 30px 0 0 30px;

        & > span {
          margin-bottom: 10px;
        }

        & > div {
          font-size: 24px;
        }
      }

      & > .videoPlayer {
        align-self: end;
        height: 100%;
        max-height: 340px;
        object-fit: cover;
        border-radius: 16px;
      }
    }

    .resourceIntroductionItemItemStartPosition {
      opacity: 0;
      transform: translateY(20px);
    }
  }
}

.resourceIntroductionItemItemAnimation {
  animation: featureAnimation 0.5s linear calc(var(--d) * 1s) forwards;
}

@keyframes  featureAnimation{
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media all and (max-width: 1580px) {
  .resourceContainer {
    max-width: calc(100% - 40px);

    .resourceIntroductionBox {
      .resourceIntroductionItemItem {
        max-width: 32%;
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .resourceContainer {
    max-width: 990px;
    overflow: hidden;
    margin: 0 auto;

    .resourceIntroductionBox {
      .resourceIntroductionItemItem {
        max-width: 480px !important;
      }
    }
  }
}

@media all and (max-width: 1020px) {
  .resourceContainer {
    max-width: 480px;
    overflow: hidden;
    margin: 0 auto;

    .resourceIntroductionBox {
      .resourceIntroductionItemItem {
        max-width: 480px !important;
      }
    }
  }
}


@media all and (max-width: 800px) {

  .resourceContainer {

    .resourceIconBox {
      padding: 0;
    }
  }
}

@media all and (max-width: 600px) {

  .resourceContainer {

    .drawResourceIntroductionTitle {
      text-align: left;
    }

    .resourceIconBox {
      width: max-content;
      justify-content: left;
      margin-right: auto;
      margin-left: 0;
      margin-top: 28px;
      row-gap: 12px;
      flex-direction: column;

      .resourceIconItem {
        font-size: 15px;

        &:last-child {
          margin-left: 0;
        }

        .resourceIconListItem {
          width: 20px;
          height: 20px;

          &:before {
            width: 7px;
            height: 3.5px;
            transform: rotate(-45deg) translate(0, calc(50% + 3.5px)) !important;
          }
        }
      }
    }

    .resourceIntroductionBox {
      margin-top: 12px;

      .resourceIntroductionItemItem {
        margin-top: 12px;
        height: 250px;

        & > div {
          & > span {
            font-size: 30px;
          }

          & > div {
            font-size: 16px;
          }
        }

        & > .videoPlayer {
          width: 100%;
          max-height: 250px;
        }
      }
    }
  }
}